import React, { useEffect, useState } from 'react';
import { StartIcon } from '../icons/StartIcon';
import { StopIcon } from '../icons/StopIcon';
import { LeftArrowIcon } from '../icons/LeftArrowIcon';
import { Pagination, Autoplay } from 'swiper/modules';
import Swiper from 'swiper';
import { useAppSelector } from '../../lib/hooks';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { isMobileOnly } from 'react-device-detect';
type PropType = {
  slides: any[];
  direction: string;
  isLoading?: boolean;
};

const customLoader = ({ src, width, quality }: any) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

const ImageCarousel: React.FC<PropType> = (props) => {
  const router = useRouter();
  const { slides, direction, isLoading } = props;
  const [swiperValue, setSwiperValue] = useState<HTMLLinkElement | any>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const cleverTap = useAppSelector(({ global }) => global.clevertap);

  function updateVisitedBullets(slides: Number[], activeIndex: Number) {
    var bullets = document.querySelectorAll('.swiper-pagination-bullet');
    bullets.forEach((bullet, index) => {
      bullet.classList.remove(
        'swiper-pagination-bullet-visited',
        'swiper-pagination-bullet-active'
      );
      if (slides.indexOf(index) > -1) {
        bullet.classList.remove('swiper-pagination-bullet-active');
        bullet.classList.add('swiper-pagination-bullet-visited');
      }
      if (index === activeIndex) {
        bullet.classList.remove('swiper-pagination-bullet-visited');
        bullet.classList.add('swiper-pagination-bullet-active');
      }
    });
  }

  useEffect(() => {
    var visitedSlides: Number[] = [];
    var swiperBanner = new Swiper('.swiper-container', {
      spaceBetween: 0,
      speed: 2000,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      modules: [Autoplay, Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable: false,
      },
      on: {
        slideChange: (swiperCore: any) => {
          const { realIndex } = swiperCore;
          if (realIndex == 0) {
            visitedSlides = [];
          }
          visitedSlides.push(realIndex);
          updateVisitedBullets(visitedSlides, realIndex);
        },
      },
    });
    setSwiperValue(swiperBanner);
    return () => {
      swiperBanner?.destroy();
    };
  }, [direction, slides]);

  return (
    <>
      <div className="relative px-6 pt-4 web-swiper lg:p-0">
        <div className="swiper rounded-[16px] lg:rounded-none">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              {slides.length > 0 ? (
                slides.map((item, index) => (
                  <div
                    className="swiper-slide relative"
                    key={index}
                    dir={direction}
                    onClick={() => {
                      cleverTap &&
                        cleverTap.event &&
                        cleverTap.event.push('hp_banner_clicked', {
                          title: item?.attributes?.Title,
                          subtitle: item?.attributes?.Subtitle,
                          description: item?.attributes?.description,
                        });
                    }}
                  >
                    <div className="relative w-full aspect-[328/192] lg:aspect-[1440/540] min-h-[192px]">
                      <Image
                        alt="banner"
                        loader={customLoader}
                        src={`${process.env.NEXT_PUBLIC_CMS_API_URL}${item?.attributes?.MobileImage?.data?.attributes?.url}`}
                        layout="fill"
                        objectFit="cover"
                        loading="lazy"
                        width={1024}
                        height={600}
                        className="visible lg:invisible"
                      />
                      <Image
                        alt="Self Listing Hero Image"
                        // loading="lazy"
                        objectFit="cover"
                        priority={true}
                        width={1920}
                        height={720}
                        src={`${process.env.NEXT_PUBLIC_CMS_API_URL}${item?.attributes?.Image?.data?.attributes?.url}`}
                        layout="fill"
                        className="invisible lg:visible"
                      />
                    </div>
                    {/* <div className="swiper-shadow absolute" /> */}
                    <div className="top-10 ltr:left-8 rtl:right-8 lg:top-28 rtl:lg:right-72 ltr:lg:left-72 absolute">
                      <div className="text-[1.5rem] lg:text-[2.5rem] text-white font-semibold">
                        {item?.attributes?.Title}
                      </div>
                      <div className="text-[1.5rem] lg:text-[2.5rem] text-white font-semibold">
                        {item?.attributes?.Subtitle}
                      </div>
                      <div className="text-lg lg:text-xl text-white font-semibold mt-2 lg:mt-4">
                        {item?.attributes?.description}{' '}
                      </div>

                      {item?.attributes?.CtaText && (
                        <button
                          tabIndex={69 + (index + 1)}
                          onClick={() => {
                            router.push(item?.attributes?.Link);
                            if (cleverTap) cleverTap.event.push(`hp_explore`);
                          }}
                          title={item?.attributes?.CtaText}
                          onMouseEnter={() => {
                            if (swiperValue) {
                              swiperValue?.autoplay?.stop();
                              setIsPlaying(!isPlaying);
                            }
                          }}
                          onMouseLeave={() => {
                            if (swiperValue) {
                              swiperValue?.autoplay?.start();
                              setIsPlaying(!isPlaying);
                            }
                          }}
                          type="button"
                          className="rounded-full lg:rounded-none h-14 min-w-36 px-4 bg-[#E02E01] active:bg-red-700 text-white text-lg outline-none focus:outline-none mt-[20px] lg:mt-[30px] flex items-center justify-center"
                        >
                          {item?.attributes?.CtaText} <LeftArrowIcon />
                        </button>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className={` bg-white overflow-hidden shadow-[0_1px_8px_0px_rgba(0,0,0,0.1)] h-[540px]`}
                ></div>
              )}
            </div>
          </div>
        </div>
        <div className="custom-pagination">
          <div className="swiper-pagination"></div>
          {slides.length > 0 && (
            <button
              id="al"
              tabIndex={68}
              aria-label="swiperplay"
              className={`z-10 absolute ${
                direction === 'rtl' ? 'mr-[2px]' : 'left-1'
              } top-2 hidden lg:block`}
              type="button"
              onClick={() => {
                setIsPlaying(!isPlaying);
                isPlaying
                  ? swiperValue?.autoplay?.stop()
                  : swiperValue?.autoplay?.start();
              }}
            >
              {isPlaying ? <StartIcon /> : <StopIcon />}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageCarousel;
